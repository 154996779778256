<template>
    <div class="login-pages">
        <el-row>
            <el-col :span="24">
                <div class="right-login">
                    <el-form
                        ref="form"
                        :model="form"
                        class="theme-form login-form"
                    >
                        <h4>Create Your Account</h4>
                        <h6>Enter your personal details to create account</h6>

                        <el-form-item label="Fist Name">
                            <el-input placeholder="Fist Name" v-model="input1">
                                <template slot="prepend">
                                    <i class="el-icon-user"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Last Name">
                            <el-input placeholder="Last Name" v-model="input1">
                                <template slot="prepend">
                                    <i class="el-icon-user"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Company  Name">
                            <el-input
                                placeholder="Company  Name"
                                v-model="input1"
                            >
                                <template slot="prepend">
                                    <i class="el-icon-office-building"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Телефон">
                            <el-input placeholder="Телефон" v-model="input1">
                                <template slot="prepend">
                                    <i class="el-icon-mobile-phone"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Email Address">
                            <el-input
                                placeholder="test@gmail.com"
                                v-model="input1"
                            >
                                <template slot="prepend">
                                    <i class="el-icon-message"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Пароль">
                            <el-input placeholder="*******" v-model="input1">
                                <template slot="prepend">
                                    <i class="el-icon-lock"></i>
                                </template>
                            </el-input>
                        </el-form-item>

                        <div class="login autr">
                            <el-checkbox v-model="checked">
                                Agree With Privacy Policy
                            </el-checkbox>
                        </div>

                        <el-form-item>
                            <el-button
                                type="primary"
                                class="btn-primary2 mtf20 w-100"
                            >
                                <router-link
                                    class="vaqt"
                                    :to="{ name: 'Home' }"
                                >
                                    Create Account
                                </router-link>
                            </el-button>
                        </el-form-item>

                        <div class="login-form-fotter">
                            <p>
                                Already have an account?
                                <router-link
                                    class="link"
                                    :to="{ name: 'login' }"
                                >
                                    Sign in
                                </router-link>
                            </p>
                        </div>
                    </el-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "register",
    data() {
        return {
            form: {},
        };
    },
};
</script>
<style lang="scss">
.login-pages {
    width: 100%;
    display: block;
    .left-login {
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 20px;
        height: 100vh;
        .center-login-img {
            display: flex;
            flex: 1;
            justify-content: center;
            img {
                width: 80%;
            }
        }
    }

    .right-login {
        background-color: #24695c1a;
        height: 100vh;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 100vh;
        margin: 0 auto;
        padding: 30px 12px;
    }
}
.login-text {
    font-size: 28px;
    margin-bottom: 20px;
}
.assaosi-color {
    border-color: #7367f0 !important;
    background-color: #7367f0 !important;
    // border-radius: 50px;
    width: 100%;
}

.login-form {
    padding: 30px;
    width: 450px;
    background-color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.login-form h4 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 22px;
}
.login-form h6 {
    font-size: 14px;
    margin: 0;
    margin-bottom: 20px;
    color: #999;
}
.login.autr .el-checkbox__label,
.link {
    font-weight: 500;
    color: #24695c;
    text-decoration: none;
    font-size: 16px;
}
.login.autr {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
}
.login-form .el-form-item {
    margin-bottom: 10px;
}
.login-form-fotter p {
    margin-top: 20px;
    font-weight: 600;
    text-align: center;
    margin-bottom: unset;
    color: #212529;
}
.login-form .el-input-group__prepend {
    background-color: #24695c1a;

    font-size: 16px;
    color: #24695c;
}
.login-form .el-form-item__label {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: -5px;
    color: #212529;
}
.vaqt {
    color: #fff;
    font-weight: 600;
}
.mtf20 {
    margin-top: 20px;
}
</style>
